import { mdiFileOutline, mdiHomeOutline } from "@mdi/js";
import courses from "./courses";

export default [
    {
        title: "Home",
        icon: mdiHomeOutline,
        to: "home",
    },
    ...courses,
];
