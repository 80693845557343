import { mdiFileDocumentOutline, mdiPackageVariantClosed } from "@mdi/js";

export default [
    {
        title: "Courses",
        icon: mdiPackageVariantClosed,
        children: [
            {
                title: "WIN Curriculum",
                icon: mdiFileDocumentOutline,
                to: "apps-courses",
            },
        ],
    },
];
