import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        redirect: (to) => {
            const userData = JSON.parse(localStorage.getItem("userData"));

            if (userData) {
                return { name: "home" };
            }

            return { name: "auth-login", query: to.query };
        },
    },
    {
        path: "/home",
        name: "home",
        component: () => import("@/views/dash/Analytics.vue"),
        meta: {
            layout: "content",
        },
    },
    {
        path: "/login",
        name: "auth-login",
        component: () => import("@/views/Login.vue"),
        meta: {
            layout: "blank",
            resource: "Public",
            redirectIfLoggedIn: true,
        },
    },
    {
        path: "/register",
        name: "auth-register",
        component: () => import("@/views/Register.vue"),
        meta: {
            layout: "blank",
            resource: "Public",
            redirectIfLoggedIn: true,
        },
    },
    {
        path: "*",
        redirect: "error-404",
    },
    {
        path: "/apps/courses",
        name: "apps-courses",
        component: () => import("@/views/courses/Courses.vue"),
        meta: {
            layout: "content",
        },
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});

router.beforeEach((to, _, next) => {
    let userData = localStorage.getItem("userData");
    let hasToken = localStorage.getItem("accessToken") != null;
    let isLoggedIn = userData !== null && hasToken != null;

    // todo: check firebase user auth status here instead
    // https://firebase.google.com/docs/auth/web/manage-users
    // try {
    //     // see if user is actively auth'd with firebase
    //     auth = getAuth();
    //     user = auth.currentUser;

    //     console.log(auth);
    // } catch (e) {}

    if (!isLoggedIn) {
        if (to.name === "auth-login") {
            return next();
        } else {
            return next({
                name: "auth-login",
            });
        }
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        next("/");
    }

    return next();
});

export default router;
